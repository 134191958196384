import { Suspense } from 'react';
import LoadingFullPage, { LoadingFullPageProps } from '../Loadings/LoadingFullPage';

interface SuspenseDefaultProps {
  element: React.ReactNode;
}

export default function SuspenseDefault({ element }: SuspenseDefaultProps & LoadingFullPageProps) {
  return <Suspense fallback={<LoadingFullPage />}>{element}</Suspense>;
}
