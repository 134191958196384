import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import { ChangeEvent, useState } from 'react';
import { BankDetails } from '../../';
import CurrencyInput from '../../../../components/CurrencyInput';
import AddZeroToTheLeft from '../../../../helpers/AddZeroToTheLeft';
import useHandleChange, { onlyNumber } from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import useLocales from '../../../../hooks/useLocales';
import findState from '../../../../utils/findState';
import scrollToInvalidInput from '../../../../utils/scrollToInvalidInput';
import { createBankAccountSchema, createRecipientSchema } from '../../bankAccountSchemaValidator';
import ZipCodeInput from '../ZipcodeInput';
import DocumentInput from '../DocumentInput';
import PhoneInput from '../PhoneInput';
import { useHistory } from 'react-router-dom';
import ZipCodeAPI from '../../../../api/ZipCode';

const FORM_STYLES = {
  '.chakra-form__label': {
    color: '#202123',
  },
  '.chakra-form__helper-text': {
    color: '#20212380',
  },
  '.chakra-select': {
    color: '#20212380',
  },
};

interface Bank {
  ispb: string;
  code: number;
  fullName: string;
}

interface BankAccountFormProps extends StackProps {
  bankList: Bank[];
  hasBankAccountRegistered: boolean;
}

export default function BankAccountForm({
  hasBankAccountRegistered,
  bankList,
  ...rest
}: BankAccountFormProps) {
  const [isFetchingAddress, setIsFetchingAddress] = useState(false);

  const {
    form,
    setForm,
    handleChange,
    onChanged: hasChange,
    setOnChanged: setHasChange,
  } = useHandleChange<Partial<BankDetails>>({
    documentNumber: '',
    bankCode: '',
    agencia: '',
    agenciaDv: null,
    conta: '',
    contaDv: '',
    legalName: '',
    documentType: 'cpf',
    country: 'br',
    ddi: '55',
    ddd: '',
    phone: '',
    state: '',
    city: '',
    neighborhood: '',
    streetAddress: '',
    streetNumber: '',
    complementary: null,
    zipCode: '',
    birthday: '',
    monthlyIncome: null,
    professionalOccupation: '',
    responsibilityDocument: '',
  });

  const { cities, states } = useLocales(form?.state);
  const { push } = useHistory();
  const toast = useToast();

  function handleDocumentNumberChange(value: string) {
    setForm(prevState => ({ ...prevState, documentNumber: value }));

    setHasChange(true);
  }

  function handlePhoneNumberChange(value: string) {
    setForm(prevState => ({ ...prevState, phone: value }));

    setHasChange(true);
  }

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      city: '',
      state: value,
    }));

    setHasChange(true);
  }

  function handleMonthlyIncomeChange(value: number) {
    setForm(prevForm => ({
      ...prevForm,
      monthlyIncome: value,
    }));

    setHasChange(true);
  }

  function handleAnnualRevenueChange(value: number) {
    setForm(prevForm => ({
      ...prevForm,
      annualRevenue: value,
    }));

    setHasChange(true);
  }

  function handleZipCodeChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (value.length === 8) getAddressByZipCode(value);

    setForm(prevForm => ({ ...prevForm, zipCode: value }));

    setHasChange(true);
  }

  const payload = {
    ...form,
    type: form.type ?? '',
    documentNumber: form.documentNumber?.replace(/\D/g, ''),
  };

  if (hasBankAccountRegistered) {
    delete payload.documentNumber;
    delete payload.documentType;
    delete payload.ddi;
    delete payload.ddd;
    delete payload.phone;
    delete payload.country;
    delete payload.state;
    delete payload.city;
    delete payload.neighborhood;
    delete payload.streetAddress;
    delete payload.streetNumber;
    delete payload.complementary;
    delete payload.zipCode;
    delete payload.birthday;
    delete payload.monthlyIncome;
    delete payload.professionalOccupation;
    delete payload.companyName;
    delete payload.tradingName;
    delete payload.annualRevenue;
    delete payload.responsibilityDocument;
  }

  if (form.documentType === 'cnpj') {
    payload.companyName = form.companyName || '';
    payload.annualRevenue = form.annualRevenue || null;
    payload.tradingName = form.tradingName || '';
  }

  for (const key in form) {
    if (payload.hasOwnProperty(key)) {
      payload[key] = form[key] === '' ? null : form[key];
    }
  }

  async function getAddressByZipCode(zipCode: string) {
    try {
      setIsFetchingAddress(true);

      const { data: response } = await ZipCodeAPI.index(zipCode);

      if (response) {
        setForm({
          ...form,
          state: response.state,
          city: response.city,
          neighborhood: response.neighborhood,
          streetAddress: response.street,
          zipCode,
        });
      }

      // if (formValidation?.address?.zipCode) {
      //   formValidation.address.zipCode.isInvalid = false;
      //   formValidation.address.zipCode.message = '';
      // }
    } catch (error) {
      toast({
        title: 'Não foi possível localizar seu endereço...',
        description: 'Por favor, preencha todos os campos manualmente.',
        status: 'error',
        position: 'bottom-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsFetchingAddress(false);
    }
  }

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: !hasBankAccountRegistered ? createRecipientSchema : createBankAccountSchema,
    url: !hasBankAccountRegistered ? '/recipient/create-recipient' : '/users/bank-accounts',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Dados bancários cadastrados com sucesso!',
      callback: !hasBankAccountRegistered
        ? () => (window.location.href = '/profile/bank-accounts')
        : () => push('/profile/bank-accounts'),
    },
    removeNullProps: false,
  });

  async function onSubmit(event) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return (
    <VStack as="form" onSubmit={onSubmit} spacing={8} px={0.5} sx={FORM_STYLES} {...rest}>
      {!hasBankAccountRegistered && (
        <FormControl isInvalid={!!formValidation?.documentNumber?.isInvalid}>
          <FormLabel>Identificação (Tipo de Recebedor)</FormLabel>

          <FormHelperText>
            Escolha qual o tipo de pessoa do seu recebedor e preencha o documento.
          </FormHelperText>

          <HStack spacing={5} align="start" mt={2}>
            <Select
              name="documentType"
              id="documentType"
              onChange={handleChange}
              value={form?.documentType}
              focusBorderColor="gray.300"
              maxWidth={{ base: '36.25%', xl: 'none' }}
              isInvalid={false}
            >
              <option value="cpf">CPF</option>
              <option value="cnpj">CNPJ</option>
            </Select>

            <VStack w="full" align="start" spacing={1}>
              <DocumentInput
                name="documentNumber"
                id="documentNumber"
                placeholder={`Digite o ${form?.documentType === 'cpf' ? 'CPF' : 'CNPJ'}`}
                onChange={handleDocumentNumberChange}
                value={form?.documentNumber}
                focusBorderColor="gray.300"
                _placeholder={{ color: '#20212380' }}
                maxLength={30}
              />
              <FormErrorMessage m={0}>{formValidation?.documentNumber?.message}</FormErrorMessage>
            </VStack>
          </HStack>
        </FormControl>
      )}

      <FormControl isInvalid={formValidation?.bankCode?.isInvalid}>
        <FormLabel>
          Banco{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>
        <Select
          name="bankCode"
          id="bankCode"
          onChange={handleChange}
          value={form?.bankCode}
          focusBorderColor="gray.300"
        >
          <option value="" hidden>
            Selecione seu banco
          </option>

          {bankList?.map(({ ispb, code, fullName }) => (
            <option key={ispb} value={AddZeroToTheLeft(code)}>
              {`${AddZeroToTheLeft(code)} - ${fullName?.toUpperCase()}`}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{formValidation?.bankCode?.message}</FormErrorMessage>
      </FormControl>

      <HStack align="start" width="full" spacing={{ base: 2.5, xl: 5 }}>
        <FormControl isInvalid={formValidation?.agencia?.isInvalid}>
          <FormLabel>
            Agência{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>
          <Input
            type="text"
            name="agencia"
            id="agencia"
            placeholder="Número da agência"
            onChange={handleChange}
            onInput={onlyNumber}
            value={form?.agencia}
            focusBorderColor="gray.300"
            maxLength={4}
          />

          <FormErrorMessage>{formValidation?.agencia?.message}</FormErrorMessage>

          <FormHelperText fontSize="xs">
            Caso a agência não possua dígito deixar o campo “Dígito” em branco.
          </FormHelperText>
        </FormControl>

        <FormControl
          isInvalid={formValidation?.agenciaDv?.isInvalid}
          maxWidth={{ base: '33.3%', xl: '28.75%' }}
        >
          <FormLabel>Dígito</FormLabel>

          <Input
            type="text"
            name="agenciaDv"
            id="agenciaDv"
            placeholder="Dígito"
            onChange={handleChange}
            onInput={onlyNumber}
            value={form?.agenciaDv}
            focusBorderColor="gray.300"
            maxLength={1}
          />

          <FormErrorMessage>{formValidation?.agenciaDv?.message}</FormErrorMessage>
        </FormControl>
      </HStack>

      <HStack align="start" width="full" spacing={{ base: 2.5, xl: 5 }}>
        <FormControl isInvalid={formValidation?.conta?.isInvalid}>
          <FormLabel>
            Conta{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            type="text"
            name="conta"
            id="conta"
            placeholder="Digite aqui"
            onChange={handleChange}
            onInput={onlyNumber}
            value={form?.conta}
            focusBorderColor="gray.300"
            minLength={5}
            maxLength={13}
          />

          <FormErrorMessage>{formValidation?.conta?.message}</FormErrorMessage>

          <FormHelperText fontSize="xs">
            Caso a conta não possua dígito deixar o campo “Dígito” em branco.
          </FormHelperText>
        </FormControl>

        <FormControl
          maxWidth={{ base: '33.3%', xl: '28.75%' }}
          isInvalid={formValidation?.contaDv?.isInvalid}
        >
          <FormLabel>
            Dígito{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            type="text"
            name="contaDv"
            id="contaDv"
            placeholder="Digite"
            onChange={handleChange}
            onInput={onlyNumber}
            value={form?.contaDv}
            focusBorderColor="gray.300"
            minLength={1}
            maxLength={2}
          />

          <FormErrorMessage>{formValidation?.contaDv?.message}</FormErrorMessage>
        </FormControl>
      </HStack>

      <FormControl isInvalid={formValidation?.type?.isInvalid}>
        <FormLabel>
          Tipo de Conta Bancária{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>

        <Select
          name="type"
          id="type"
          onChange={handleChange}
          value={form?.type}
          focusBorderColor="gray.300"
        >
          <option value="" hidden>
            Selecione o tipo
          </option>
          <option value="conta_corrente">Conta Corrente</option>
          <option value="conta_poupanca">Conta Poupança</option>
          <option value="conta_corrente_conjunta">Conta Corrente Conjunta</option>
          <option value="conta_poupanca_conjunta">Conta Poupança Conjunta</option>
        </Select>

        <FormErrorMessage>{formValidation?.type?.message}</FormErrorMessage>
      </FormControl>

      <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing={{ base: 8, xl: 5 }}>
        <FormControl isInvalid={formValidation?.legalName?.isInvalid}>
          <FormLabel>
            Responsável da Conta Bancária{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            type="text"
            name="legalName"
            id="legalName"
            placeholder="Informe seu Nome Completo"
            onChange={handleChange}
            value={form?.legalName}
            focusBorderColor="gray.300"
            maxLength={30}
          />

          <FormErrorMessage>{formValidation?.legalName?.message}</FormErrorMessage>

          <FormHelperText fontSize="xs">
            Se sua conta for do tipo jurídica, informe os dados do sócio principal
          </FormHelperText>
        </FormControl>

        <FormControl
          isInvalid={!!formValidation?.responsibilityDocument?.isInvalid}
          hidden={form?.documentType === 'cpf'}
        >
          <FormLabel>
            CPF{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            name="responsibilityDocument"
            value={form?.responsibilityDocument}
            onChange={handleChange}
            placeholder="CPF do responsável da conta"
            focusBorderColor="gray.200"
          />

          <FormErrorMessage>{formValidation?.professionalOccupation?.message}</FormErrorMessage>
        </FormControl>

        {!hasBankAccountRegistered && (
          <FormControl isInvalid={formValidation?.birthday?.isInvalid}>
            <FormLabel>
              Data de nascimento{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <Input
              placeholder="Digite aqui"
              name="birthday"
              value={form?.birthday}
              onChange={handleChange}
              focusBorderColor="gray.300"
              maxLength={30}
              type="date"
            />

            <FormErrorMessage>{formValidation?.birthday?.message}</FormErrorMessage>
          </FormControl>
        )}
      </Stack>

      {!hasBankAccountRegistered && (
        <>
          <Stack
            direction={{ base: 'column', xl: 'row' }}
            width="full"
            spacing={{ base: 8, xl: 5 }}
          >
            <FormControl isInvalid={!!formValidation?.professionalOccupation?.isInvalid}>
              <FormLabel>
                Ocupação{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="professionalOccupation"
                value={form?.professionalOccupation}
                onChange={handleChange}
                type="professionalOccupation"
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>{formValidation?.professionalOccupation?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formValidation?.responsibilityDocument?.isInvalid}>
              <FormLabel>
                Renda Mensal
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <CurrencyInput
                placeholder="Digite aqui"
                value={form.monthlyIncome}
                onChange={handleMonthlyIncomeChange}
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>{formValidation?.monthlyIncome?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <HStack width="full" align="start" spacing={5}>
            <FormControl width="150px" isInvalid={!!formValidation?.ddd?.isInvalid}>
              <FormLabel>
                DDD{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="ddd"
                value={form?.ddd}
                onChange={handleChange}
                onInput={onlyNumber}
                type="ddd"
                maxLength={2}
                focusBorderColor="gray.200"
                placeholder="Digite aqui"
              />

              <FormErrorMessage>{formValidation?.ddd?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formValidation?.phone?.isInvalid}>
              <FormLabel>
                Telefone{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <PhoneInput
                name="phone"
                value={form?.phone}
                onChange={handlePhoneNumberChange}
                placeholder="Digite aqui"
                type="phone"
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>{formValidation?.phone?.message}</FormErrorMessage>
            </FormControl>
          </HStack>

          <FormControl isInvalid={!!formValidation?.zipCode?.isInvalid}>
            <FormLabel>
              CEP{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <ZipCodeInput
              name="zipCode"
              value={form?.zipCode}
              onChange={handleZipCodeChange}
              placeholder="Digite aqui"
              type="zipCode"
              maxLength={20}
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>{formValidation?.zipCode?.message}</FormErrorMessage>
          </FormControl>

          <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing={{ base: 8, xl: 5 }}>
            <FormControl isInvalid={!!formValidation?.state?.isInvalid} flex={1}>
              <FormLabel>
                Estado{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Skeleton isLoaded={!isFetchingAddress}>
                <Select
                  name="state"
                  value={findState(form?.state, states)}
                  onChange={handleStateChange}
                  focusBorderColor="gray.200"
                >
                  <option value="" hidden>
                    Selecione
                  </option>

                  {states.map(state => (
                    <option key={state.id} value={state.sigla}>
                      {state.nome}
                    </option>
                  ))}
                </Select>
              </Skeleton>

              <FormErrorMessage>{formValidation?.state?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formValidation?.city?.isInvalid} flex={1}>
              <FormLabel>
                Cidade{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Skeleton isLoaded={!isFetchingAddress}>
                <Select
                  name="city"
                  value={form?.city}
                  onChange={handleChange}
                  isDisabled={!form.state}
                  focusBorderColor="gray.200"
                >
                  <option value="" hidden>
                    Selecione
                  </option>

                  {cities.map(city => (
                    <option key={city.id}>{city.nome}</option>
                  ))}
                </Select>
              </Skeleton>

              <FormErrorMessage>{formValidation?.city?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <FormControl isInvalid={!!formValidation?.neighborhood?.isInvalid}>
            <FormLabel>
              Bairro{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <Skeleton isLoaded={!isFetchingAddress}>
              <Input
                placeholder="Digite aqui"
                name="neighborhood"
                value={form?.neighborhood}
                onChange={handleChange}
                type="neighborhood"
                focusBorderColor="gray.200"
              />
            </Skeleton>

            <FormErrorMessage>{formValidation?.neighborhood?.message}</FormErrorMessage>
          </FormControl>

          <Stack
            direction={{ base: 'column', xl: 'row' }}
            width="full"
            spacing={{ base: 8, xl: 5 }}
          >
            <FormControl isInvalid={!!formValidation?.streetAddress?.isInvalid}>
              <FormLabel>
                Rua{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Skeleton isLoaded={!isFetchingAddress}>
                <Input
                  placeholder="Digite aqui"
                  name="streetAddress"
                  value={form?.streetAddress}
                  onChange={handleChange}
                  type="streetAddress"
                  focusBorderColor="gray.200"
                />
              </Skeleton>

              <FormErrorMessage>{formValidation?.streetAddress?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!formValidation?.streetNumber?.isInvalid}
              width={{ base: '100%', xl: '33%' }}
            >
              <FormLabel>
                Número
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Skeleton isLoaded={!isFetchingAddress}>
                <Input
                  name="streetNumber"
                  value={form?.streetNumber}
                  onChange={handleChange}
                  placeholder="Digite aqui"
                  type="streetNumber"
                  focusBorderColor="gray.200"
                />
              </Skeleton>

              <FormErrorMessage>{formValidation?.streetNumber?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formValidation?.complementary?.isInvalid}>
              <FormLabel>Complemento</FormLabel>

              <Skeleton isLoaded={!isFetchingAddress}>
                <Input
                  name="complementary"
                  value={form?.complementary}
                  onChange={handleChange}
                  placeholder="Digite aqui"
                  type="complementary"
                  focusBorderColor="gray.200"
                />
              </Skeleton>

              <FormErrorMessage>{formValidation?.complementary?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          {form.documentType === 'cnpj' && (
            <>
              <FormControl isInvalid={formValidation?.companyName?.isInvalid}>
                <FormLabel>
                  Razão Social{' '}
                  <Text as="span" color="red.500">
                    *
                  </Text>
                </FormLabel>

                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder="Digite aqui"
                  onChange={handleChange}
                  value={form?.companyName}
                  focusBorderColor="gray.300"
                />

                <FormErrorMessage>{formValidation?.companyName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.tradingName?.isInvalid}>
                <FormLabel>
                  Nome Fantasia{' '}
                  <Text as="span" color="red.500">
                    *
                  </Text>
                </FormLabel>

                <Input
                  type="text"
                  name="tradingName"
                  id="tradingName"
                  placeholder="Digite aqui"
                  onChange={handleChange}
                  value={form?.tradingName}
                  focusBorderColor="gray.300"
                />

                <FormErrorMessage>{formValidation?.tradingName?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formValidation?.annualRevenue?.isInvalid}>
                <FormLabel>
                  Faturamento Anual
                  <Text as="span" color="red.500">
                    *
                  </Text>
                </FormLabel>

                <CurrencyInput
                  placeholder="Digite aqui"
                  value={form.annualRevenue}
                  onChange={handleAnnualRevenueChange}
                  focusBorderColor="gray.200"
                />

                <FormErrorMessage>{formValidation?.annualRevenue?.message}</FormErrorMessage>
              </FormControl>
            </>
          )}
        </>
      )}

      <HStack width="full" justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}>
        <Button
          width={{ base: 'full', md: 200, lg: 200 }}
          type="submit"
          colorScheme="primary"
          color="textColor"
          isDisabled={!hasChange || isSubmitting}
          isLoading={isSubmitting}
        >
          {!hasBankAccountRegistered ? 'Salvar informações' : 'Adicionar'}
        </Button>
      </HStack>
    </VStack>
  );
}
