import Platform from '../api/Platform';
import axios from 'axios';
import process from 'process/browser';

const REACT_APP_REMOTE_LOAD_IMAGES = process.env.REACT_APP_REMOTE_LOAD_IMAGES === 'true';

const platformsInS3 = ['professorsoares.com.br', 'ifbplay.com.br', 'aprenda.meubizu.com.br'];

const api = axios.create();

type FoldersType =
  | 'logos'
  | 'profiles'
  | 'course'
  | 'thumbnails'
  | 'drive'
  | 'archives'
  | 'multimedia'
  | 'temp'
  | 'audio'
  | 'assets'
  | 'banners'
  | 'favicons'
  | 'biography-photos'
  | 'instructor-photos'
  | 'certificates';

export function EscapeFileName(fileName: string) {
  const newFileNormalized = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const fileNameParts = /(.*)(\.[\w]{1,}$)/g;

  const result = fileNameParts.exec(newFileNormalized);

  if (!result.length) {
    throw new Error('Esse arquivo não pode ser enviado.');
  }

  const [, name, extension] = result;

  const newFileNameSanitized = name.replace(/\W+/g, '_');

  return newFileNameSanitized.concat(extension);
}

async function getPreSignedURLToUpload(filename: string, folder: FoldersType) {
  const { data: presignedURLToUpload } = await Platform.createPresignedURLToUpload(
    encodeURI(EscapeFileName(filename)),
    folder
  );

  const query = new URLSearchParams(presignedURLToUpload.signedUrl);

  let newFileNameEncoded = query.get('key');

  if (platformsInS3.includes(window.location.hostname)) {
    newFileNameEncoded = presignedURLToUpload.signedUrl.replace(/.*\/(.*\..{1,4})\?(.*)/g, '$1');
  }

  const key = folder.concat('/', newFileNameEncoded);

  return { key, newFileName: decodeURI(newFileNameEncoded), presignedURLToUpload };
}

async function deleteFileByKey(key: string) {
  await Platform.deleteFileByKey(encodeURI(key));
}

export function parseFile(key: string, file: File) {
  const hostname = window.location.hostname;

  if (platformsInS3.includes(hostname) && REACT_APP_REMOTE_LOAD_IMAGES) return file;

  const formData = new FormData();

  formData.append(key, file, file.name);

  return formData;
}

async function upload(file: File, folder: FoldersType) {
  let key = '';

  try {
    const uploadParams = await UploadHelper.getPreSignedURLToUpload(file.name, folder);

    key = uploadParams.key;

    const fileData = parseFile(key, file);

    const { data: uploadResponse } = await api.put(
      uploadParams.presignedURLToUpload.signedUrl,
      fileData
    );

    return {
      newFileName: uploadParams.newFileName,
      link: uploadResponse.data,
      signedUrl: uploadParams.presignedURLToUpload.signedUrl,
      platformId: uploadParams.presignedURLToUpload.platformId,
    };
  } catch (error) {
    if (key) {
      await Platform.deleteFileByKey(key);
    }
    throw error;
  }
}

const UploadHelper = {
  getPreSignedURLToUpload,
  deleteFileByKey,
  parseFile,
  upload,
};

export default UploadHelper;
