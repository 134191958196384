import { BOLETO_DAYS_OPTIONS, PAYMENT_METHOD_OPTIONS, USER_FORM_OPTIONS } from './constants';
import { CheckoutSettingsEditPaymentProps } from './types';
import useCheckoutSettingsEditPayment from './useCheckoutSettingsEditPayment';

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';

export default function CheckoutSettingsEditPayment({
  onIsLoadingChange,
}: CheckoutSettingsEditPaymentProps) {
  const { form, isSubmitting, formValidation, handleChange, handleCancelButtonClick, onSubmit } =
    useCheckoutSettingsEditPayment({
      onIsLoadingChange,
    });

  return (
    <Box mt={10}>
      <Heading fontSize="xl" fontWeight="medium" color="#202123">
        Opções de pagamento
      </Heading>

      <VStack
        as="form"
        align="start"
        mt="1.125rem"
        spacing={8}
        sx={{
          '.chakra-checkbox__label, .chakra-form__label': { fontSize: 'sm', color: '#202123' },
        }}
        noValidate
        onSubmit={onSubmit}
      >
        <FormControl as="fieldset" isInvalid={formValidation?.paymentMethod.isInvalid}>
          <FormLabel as="legend">Seleção de pagamento</FormLabel>

          <CheckboxGroup
            value={form?.paymentMethod}
            onChange={value => handleChange('paymentMethod', value)}
          >
            <VStack spacing={2} align="start">
              {PAYMENT_METHOD_OPTIONS.map(paymentMethod => (
                <Checkbox
                  key={paymentMethod.value}
                  colorScheme="primary"
                  iconColor="secondary.500"
                  value={paymentMethod.value}
                >
                  {paymentMethod.label}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>

          <FormErrorMessage>{formValidation?.paymentMethod.message}</FormErrorMessage>
        </FormControl>

        {form.paymentMethod?.includes('boleto') && (
          <FormControl
            as="fieldset"
            isInvalid={formValidation?.daysThatCanGenerateBoleto.isInvalid}
          >
            <FormLabel as="legend">Dias que podem gerar boleto</FormLabel>

            <CheckboxGroup
              value={form?.daysThatCanGenerateBoleto}
              onChange={value => handleChange('daysThatCanGenerateBoleto', value)}
            >
              <VStack spacing={2} align="start">
                {BOLETO_DAYS_OPTIONS.map(day => (
                  <Checkbox
                    key={day.value}
                    colorScheme="primary"
                    iconColor="secondary.500"
                    value={day.value}
                  >
                    {day.label}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>

            <FormErrorMessage>{formValidation?.daysThatCanGenerateBoleto.message}</FormErrorMessage>
          </FormControl>
        )}

        <VStack align="start">
          <Heading fontSize="xl" fontWeight="medium" color="#202123">
            Opções de formulário
          </Heading>

          <FormControl as="fieldset" isInvalid={formValidation?.userFormForPurchase.isInvalid}>
            <FormLabel as="legend">Formulário de compra</FormLabel>

            <CheckboxGroup
              value={form?.userFormForPurchase}
              onChange={value => handleChange('userFormForPurchase', value)}
            >
              <VStack spacing={2} align="start">
                {USER_FORM_OPTIONS.map(option => (
                  <Checkbox
                    key={option.value}
                    colorScheme="primary"
                    iconColor="secondary.500"
                    value={option.value}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>

            <FormErrorMessage>{formValidation?.userFormForPurchase.message}</FormErrorMessage>
          </FormControl>
        </VStack>

        <HStack as="footer" w="full" justify="end">
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
          >
            Avançar
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
