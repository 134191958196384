import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useState } from 'react';
import { RevenueSliptType } from '..';
import { axiosBackend } from '../../../../../api/Configuration';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';

interface IFormData {
  id: string;
  courseId: string;
  percentage: string;
}

export default function AddStandardSplit({ isOpen, onClose, getStandardSplit }) {
  const [availablePercentage, setAvailablePercentage] = useState<string | null>(null);
  const [percentages, setPercentages] = useState({
    availablePercentage: '',
    prolunoPercentage: '',
  });
  const [isLoadingPercentages, setIsLoadingPercentages] = useState(true);

  const { data: courseListResponse } = useFetch<UnificadaFront.ResponseJSON<any>>({
    url: `/revenue-split/course-list`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const courses = courseListResponse?.data;

  const { data: coProducersResponse, loading: isLoadingCoProducers } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    url: `/users/co-producers`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const coProducers = coProducersResponse?.data;
  const hasCoProducers = !isLoadingCoProducers && coProducers.length;

  const { form, setForm } = useHandleChange<IFormData>({
    id: '',
    courseId: '',
    percentage: '',
  });

  async function percentageAvailableByCourse(courseId: number, revenueType: RevenueSliptType) {
    try {
      setIsLoadingPercentages(true);

      const { data: response } = await axiosBackend().get(
        `/revenue-split/percentage-available-by-course/${courseId}/course?revenueType=${revenueType}`
      );

      if (response.data) {
        setPercentages({
          availablePercentage: parseFloat(response.data.availablePercentage).toFixed(2),
          prolunoPercentage: parseFloat(response.data.prolunoPercentage).toFixed(2),
        });
        setAvailablePercentage(response.data.availablePercentage);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingPercentages(false);
    }
  }

  async function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    setForm(prevPost => ({ ...prevPost, [name]: value }));

    if (name === 'courseId') {
      await percentageAvailableByCourse(parseInt(value), 'DEFAULT');
    }

    if (name === 'percentage') {
      let numericValue =
        value !== '' ? parseInt(value.replace(/\D/g, '')).toString() : value.replace(/\D/g, '');

      let formattedValue = numericValue;

      if (numericValue.length === 1) {
        formattedValue = `0.0${numericValue[0]}`;
      } else if (numericValue.length === 2) {
        formattedValue = `0.${numericValue[0]}${numericValue[1]}`;
      } else if (numericValue.length === 3) {
        formattedValue = `${numericValue[0]}.${numericValue[1]}${numericValue[2]}`;
      } else if (numericValue.length > 3) {
        formattedValue = `${numericValue[0]}${numericValue[1]}.${numericValue[2]}${numericValue[3]}`;
      }

      setForm(prevPost => ({ ...prevPost, [name]: formattedValue }));

      if (numericValue === '') {
        setAvailablePercentage(percentages?.availablePercentage);
        setForm(prevPost => ({ ...prevPost, percentage: '' }));
        return;
      }

      // Regex para validar número flutuante entre 0.01 e 99.99
      const isValidPercentage = /^(\d{1,2}(\.\d{1,2})?|100)$/.test(formattedValue);

      if (!isValidPercentage) {
        // Se o valor não for válido, pode tratar o erro aqui se necessário
        return;
      }

      setAvailablePercentage(
        (parseFloat(percentages?.availablePercentage) - parseFloat(formattedValue)).toString()
      );

      if (parseFloat(formattedValue) > parseFloat(percentages?.availablePercentage)) {
        setForm(prevPost => ({
          ...prevPost,
          percentage: parseFloat(percentages?.availablePercentage).toFixed(2),
        }));
        setAvailablePercentage('0');
        return;
      }

      if (parseFloat(formattedValue) < 0.01) {
        setForm(prevPost => ({ ...prevPost, percentage: '1' }));
        setAvailablePercentage(percentages?.availablePercentage);
        return;
      }
    }
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    data: {
      user_id: parseInt(form.id),
      course_id: parseInt(form.courseId),
      percentage: parseFloat(form.percentage),
    },
    url: '/revenue-split/revenue-default',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Compartilhamento adicionado com sucesso!',
      callback() {
        getStandardSplit();
        onCloseModal();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  function onCloseModal() {
    setForm({
      id: '',
      courseId: '',
      percentage: '',
    });
    setAvailablePercentage(null);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onCloseModal}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />

      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={800} fontSize="xl" textAlign="start">
          Adicionar um usuário
        </ModalHeader>

        <ModalCloseButton onClick={onCloseModal} />

        <ModalBody>
          <Stack width="full" spacing="10px">
            <Stack width="full" spacing="10px">
              <Text fontWeight="600" fontSize="sm">
                Usuário
              </Text>
              <Select
                name="id"
                onChange={handleChange}
                focusBorderColor="primary.400"
                value={form.id}
                isRequired
                size="sm"
                borderRadius={5}
              >
                <option value="" hidden>
                  Selecione um usuário
                </option>
                {hasCoProducers ? (
                  coProducers.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))
                ) : (
                  <option value="">Você não cadastrou usuários.</option>
                )}
              </Select>

              <Text fontSize="sm" fontWeight="500" color="#20212380">
                OBS: Para selecionar um usuário é necessário cadastra-lo. Para isso vá no menu
                lateral em Usuários {'>'} Usuários da conta.
              </Text>
            </Stack>

            <Stack width="full" spacing="10px">
              <Text fontWeight="600" fontSize="sm">
                Escolher produto
              </Text>

              <Select
                isRequired
                onChange={handleChange}
                value={form.courseId}
                name="courseId"
                size="sm"
                borderRadius={5}
              >
                <option value="" hidden>
                  Escolha um produto
                </option>
                {courses?.length ? (
                  courses?.map(course => (
                    <option key={course.id} value={course.id}>
                      {course.name}
                    </option>
                  ))
                ) : (
                  <option value="">Você não possui cursos.</option>
                )}
              </Select>
            </Stack>

            <Stack width="full" spacing="10px">
              <Text fontWeight="600" fontSize="sm">
                Porcentagem
              </Text>

              <InputGroup size="sm">
                <Input
                  isRequired
                  type="text"
                  name="percentage"
                  placeholder="Digite uma porcentagem"
                  focusBorderColor="primary.400"
                  borderRadius={5}
                  maxLength={5}
                  isDisabled={!form.courseId}
                  value={form.percentage}
                  onChange={handleChange}
                />
                <InputRightAddon children="%" height={8} />
              </InputGroup>

              <VStack alignItems="flex-start">
                <Text>
                  Porcentagem da proluno:{' '}
                  <Text as="span" fontWeight="500">
                    {!isLoadingPercentages && percentages?.prolunoPercentage
                      ? `${parseFloat(percentages?.prolunoPercentage).toFixed(2)}%`
                      : '-'}
                  </Text>
                </Text>
                <Text>
                  Porcentagem disponível:{' '}
                  <Text as="span" fontWeight="500">
                    {`${
                      !isLoadingPercentages && availablePercentage
                        ? `${parseFloat(availablePercentage).toFixed(2)}%`
                        : '-'
                    }`}
                  </Text>
                </Text>
              </VStack>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="gray" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button
              size="sm"
              type="submit"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isSubmitting || !form.courseId || !form.id}
              isLoading={isSubmitting}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
