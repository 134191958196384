import React, { createContext, useEffect, useState } from 'react';
import { UTM_LOCAL_STORAGE } from '../helpers/LocalStorageHelper';

export interface UTMData {
  utm_id: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
}

interface UTMContextValue {
  utmData: UTMData;
  setUTMData: React.Dispatch<React.SetStateAction<UTMData>>;
}

const UTMContext = createContext<UTMContextValue>({
  utmData: {
    utm_id: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
    utm_content: null,
  },
  setUTMData: () => {
    // Esta função será substituída pelo UTMProvider
  },
});

const UTMProvider = ({ children }: { children: React.ReactNode }) => {
  const [utmData, setUTMData] = useState<UTMData>(() => {
    // Recuperar os valores do localStorage se existirem
    const utmDataFromStorage = localStorage.getItem(UTM_LOCAL_STORAGE);
    return utmDataFromStorage
      ? JSON.parse(utmDataFromStorage)
      : {
          utm_id: null,
          utm_source: null,
          utm_medium: null,
          utm_campaign: null,
          utm_term: null,
          utm_content: null,
        };
  });

  useEffect(() => {
    // Função para ler os valores dos parâmetros UTM da URL
    const urlParams = new URLSearchParams(window.location.search);

    const utm_id = urlParams.get('utm_id');
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');
    const utm_term = urlParams.get('utm_term');
    const utm_content = urlParams.get('utm_content');

    const UTMParams = {
      utm_id: utm_id || null,
      utm_source: utm_source || null,
      utm_medium: utm_medium || null,
      utm_campaign: utm_campaign || null,
      utm_term: utm_term || null,
      utm_content: utm_content || null,
    };

    // Armazenar os valores no estado global
    setUTMData(UTMParams);

    // Armazenar os valores no localStorage como um único objeto
    localStorage.setItem(UTM_LOCAL_STORAGE, JSON.stringify(UTMParams));
  }, []);

  return <UTMContext.Provider value={{ utmData, setUTMData }}>{children}</UTMContext.Provider>;
};

export { UTMContext, UTMProvider };
