import { ROLE_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import { IMenuItemProps } from './MenuList/MenuItem';

const platformsWithRecurrence = [
  'presets.liviabrasil.com.br',
  'cursos.liviabrasil.com.br',
  'sandbox-eudesjr.proluno.com.br',
  'velasquez.proluno.com.br',
  'local.proluno.com.br',
  'questoes.qblitz.com.br',
  'ensinedoseujeito.proluno.com.br',
  'rhskills.com.br',
  'academiadodinheiro.proluno.com.br',
  'professorsoares.com.br',
  'sobrancelhadesejada.proluno.com.br',
  'conceitoplay.com.br',
  'ifbplay.com.br',
  'pollylyra.com',
  'geise.proluno.com.br',
  'aprenda.meubizu.com.br',
  'marrocoscursos.com.br',
  'litterartcursos.proluno.com.br',
];

const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

const isAdmin = role === 'ADMIN';

const hostname = window.location.hostname;

const isPlatformWithRecurrence = platformsWithRecurrence.includes(hostname);

const defaultMenuList: IMenuItemProps[] = [
  {
    name: 'Início',
    icon: 'homeIcon',
    path: '/home',
  },
  {
    name: 'Construa seu site',
    icon: 'pageBuilderIcon',
    path: '/page-builder',
  },

  {
    name: 'Gerenciamento de produto',
    icon: 'productManagerIcon',
    submenuItems: [
      {
        name: 'Produtos/Cursos',
        path: '/courses',
      },
      {
        name: 'Biblioteca de vídeos',
        path: '/video-library',
      },
      {
        name: 'Categorias',
        path: '/categories',
      },
      {
        name: 'Gestão de checkout',
        path: '/checkout-settings',
      },
    ],
  },

  isPlatformWithRecurrence && {
    name: 'Assinatura',
    icon: 'recurrenceIcon',
    submenuItems: [
      {
        name: 'Gestão de planos',
        path: '/recurrence/plan-management',
      },
      {
        name: 'Dashboard de assinatura',
        path: '/recurrence/dashboard',
      },
    ],
  },

  {
    name: 'Afiliações',
    icon: 'myAffiliationsIcon',
    submenuItems: [
      {
        name: 'Produtos para afiliação',
        path: '/affiliations/products',
      },
      {
        name: 'Afiliados',
        path: '/affiliations/affiliates',
      },
    ],
  },
  {
    name: 'Área de Membros',
    icon: 'membersAreaIcon',
    path: '/members-area-management',
  },
  {
    name: 'Ferramentas',
    icon: 'toolsIcon',
    submenuItems: [
      {
        name: 'Biblioteca de Questões',
        path: '/tools/question-library',
      },
      {
        name: 'Filtros',
        path: '/tools/filters',
      },
      {
        name: 'Questões Externas',
        path: '/tools/external-question',
      },
      {
        name: 'Estatísticas de questões',
        path: '/tools/question-statistics',
      },
    ],
  },
  {
    name: 'Financeiro e vendas',
    icon: 'financialIcon',
    submenuItems: [
      {
        name: 'Dashboard',
        path: `/dashboard`,
      },
      {
        name: 'Transações',
        path: '/financial',
      },
      {
        name: 'Compartilhamento de receita',
        path: '/revenue-split/standard',
      },
      {
        name: 'Extrato',
        path: '/extract',
      },
      {
        name: 'Gestão de assinaturas',
        path: '/signatures',
      },
    ],
  },
  {
    name: 'Marketing',
    icon: 'marketingIcon',
    submenuItems: [
      {
        name: 'Cupons',
        path: '/coupons',
      },
      {
        name: 'Blog',
        path: '/internal-blog',
      },
      {
        name: 'Order Bump',
        path: '/order-bump',
      },
      {
        name: 'Gerador de UTM',
        path: '/utm-generator',
      },
      {
        name: 'Base de leads',
        path: '/lead-base',
      },
      {
        name: 'Segmentações',
        path: '/segmentation',
      },
      {
        name: 'Promoções',
        path: '/promotions',
      },
    ],
  },
  {
    name: 'Usuários',
    icon: 'usersIcon',
    submenuItems: [
      {
        name: 'Usuários da conta',
        path: '/user-base',
      },
      {
        name: 'Base de alunos',
        path: '/student-base',
      },
      {
        name: 'Instrutores',
        path: '/instructors',
      },
    ],
  },
  {
    name: 'Gestão de Polos',
    icon: 'poleManagementIcon',
    path: '/pole-management',
  },
  {
    name: 'Integrações',
    icon: 'integrationsIcon',
    path: '/integrations',
  },
  {
    name: 'Configurações',
    icon: 'configIcon',
    submenuItems: [
      {
        name: 'SEO',
        path: '/settings/seo',
      },
      {
        name: 'URL do site',
        path: '/settings/url',
      },
      {
        name: 'Notificações',
        path: '/settings/notifications',
      },
    ],
  },
  {
    name: 'Perfil',
    icon: 'profileIcon',
    submenuItems: [
      {
        name: 'Editar Perfil',
        path: '/profile/user',
      },
      {
        name: 'Dados bancários',
        path: '/profile/bank-accounts',
      },
    ],
  },
  {
    name: 'Suporte',
    icon: 'supportIcon',
    href: 'https://proluno.freshdesk.com/support/home',
  },

  isAdmin && {
    name: 'Admin',
    icon: 'accountKey',
    submenuItems: [
      {
        name: 'Plano',
        path: '/internal-manager/plans',
      },
    ],
  },
];

const instructorMenuList: IMenuItemProps[] = [
  {
    name: 'Gerenciamento de produto',
    icon: 'productManagerIcon',
    submenuItems: [
      {
        name: 'Produtos/Cursos',
        path: '/courses',
      },
    ],
  },
  {
    name: 'Financeiro e vendas',
    icon: 'financialIcon',
    submenuItems: [
      {
        name: 'Dashboard',
        path: `/dashboard`,
      },
      {
        name: 'Transações',
        path: '/financial',
      },
      {
        name: 'Extrato',
        path: '/extract',
      },
    ],
  },
  {
    name: 'Ferramentas',
    icon: 'toolsIcon',
    submenuItems: [
      {
        name: 'Biblioteca de Questões',
        path: '/tools/question-library',
      },
      {
        name: 'Filtros',
        path: '/tools/filters',
      },
      {
        name: 'Questões Externas',
        path: '/tools/external-question',
      },
      {
        name: 'Estatísticas de questões',
        path: '/tools/question-statistics',
      },
    ],
  },
  {
    name: 'Perfil',
    icon: 'profileIcon',
    submenuItems: [
      {
        name: 'Editar Perfil',
        path: '/profile/user',
      },
      {
        name: 'Dados bancários',
        path: '/profile/bank-accounts',
      },
    ],
  },
  {
    name: 'Suporte',
    icon: 'supportIcon',
    href: 'https://proluno.freshdesk.com/support/home',
  },
];

const affiliateMenuList: IMenuItemProps[] = [
  {
    name: 'Afiliações',
    icon: 'myAffiliationsIcon',
    submenuItems: [
      {
        name: 'Minhas afiliações',
        path: '/affiliations/my-affiliations',
      },
    ],
  },
  {
    name: 'Financeiro e vendas',
    icon: 'financialIcon',
    submenuItems: [
      {
        name: 'Dashboard',
        path: `/dashboard`,
      },
      {
        name: 'Transações',
        path: '/financial',
      },
      {
        name: 'Extrato',
        path: '/extract',
      },
    ],
  },
  {
    name: 'Integrações',
    icon: 'integrationsIcon',
    path: '/integrations',
  },
  {
    name: 'Perfil',
    icon: 'profileIcon',
    submenuItems: [
      {
        name: 'Editar Perfil',
        path: '/profile/user',
      },
      {
        name: 'Dados bancários',
        path: '/profile/bank-accounts',
      },
    ],
  },
  {
    name: 'Suporte',
    icon: 'supportIcon',
    href: 'https://proluno.freshdesk.com/support/home',
  },
];

export { affiliateMenuList, defaultMenuList, instructorMenuList };
